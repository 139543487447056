import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Define the allowed wallet address
const allowedWallet = '0xbdc8186faa4c50061948edfdfc4a5b534c42a45d';

const BlacklistUrls = () => {
  const [urls, setUrls] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null); // Allow both string and null
  const [walletAddress, setWalletAddress] = useState<string | null>(null); // To hold the wallet address from localStorage

  // Fetch the currently blacklisted URLs when the component loads
  const fetchBlacklistedUrls = async () => {
    try {
      const response = await axios.get('/wp-json/custom/v1/get-blacklist');
      setUrls(response.data.join('\n')); // Convert the array of URLs into newline-separated string
      setLoading(false);
    } catch (err) {
      setError('Error fetching blacklisted URLs'); // Set error message as a string
      setLoading(false);
    }
  };

  // Update the blacklist when form is submitted
  const handleUpdate = async () => {
    try {

      const currentHostname = window.location.href;
        const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
        const linkUrl = currentHostname.includes(`${stagingKey}`)
            ? `https://${stagingKey}.peaxel.me`
            : 'https://peaxel.me';

      await axios.post(`${linkUrl}/wp-json/custom/v1/update-blacklist`, {
        urls,
      });
      alert('Blacklist updated successfully');
    } catch (err) {
      setError('Error updating blacklist');
    }
  };

  useEffect(() => {
    // Fetch the blacklisted URLs when component loads
    fetchBlacklistedUrls();

    // Get the wallet address from localStorage
    const storedWalletAddress = localStorage.getItem('walletAddress');
    setWalletAddress(storedWalletAddress);
  }, []);

  // If walletAddress is not loaded yet or doesn't match, render nothing
  if (!walletAddress || walletAddress.toLowerCase() !== allowedWallet.toLowerCase()) {
    return null;
  }

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <h2>Blacklist URLs</h2>
      <textarea
        value={urls}
        onChange={(e) => setUrls(e.target.value)}
        rows={10}
        cols={50}
        placeholder="Enter URLs, one per line"
      />
      <br />
      <button onClick={handleUpdate}>Update Blacklist</button>
    </div>
  );
};

export default BlacklistUrls;
